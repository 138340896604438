/* Estilos generales para el body */
body, html {
    margin: 0;
    padding: 0;
}

.disclaimer-popup{
    color: black;
}

/* Contenedor principal de productos químicos */
.body-regencia {
    margin-top: 15vh;
    display: flex;
    flex-direction: column; /* Cambiado para permitir desplazamiento vertical */
    align-items: center;
    height: 100%;
    min-height: 100vh;
    width: 100vw; /* Cambiado de 100vw a 100% */
    background-color: #ffffff;
    overflow-y: auto; /* Permite el desplazamiento vertical si es necesario */
}

.img-regencia {
    position: relative; /* Permite posicionar elementos hijos */
    width: 100%; /* Ancho completo */
    height: 25vh; /* Altura deseada */
    overflow: hidden; /* Oculta el desbordamiento */
    margin-bottom: 5vh;
    background-color: black;
}

.fondo-regencia {
    position: absolute; /* Cambiado a absolute para que la imagen ocupe el contenedor */
    top: -200%; /* Ajusta este valor para mover la imagen hacia arriba o abajo */
    left: 0; /* Alineado a la izquierda */
    width: 100%; /* Ajusta el ancho de la imagen */
    height: 100vh; /* Cambiado a auto para mantener la proporción */
    object-fit: cover; /* Mantiene la proporción mientras cubre el área */
    z-index: 1; /* Asegura que la imagen esté detrás del contenido */
    opacity: 0.5;
}

.img-regencia h1 {
    position: relative; /* Permite que el texto esté por encima de la imagen */
    z-index: 2; /* Asegura que el texto esté en el frente */
    color: rgb(255, 255, 255); /* Ajusta el color según el contraste que desees */
    text-align: center; /* Centra el texto */
    padding: 20px; /* Espaciado interno para que el texto no esté pegado a los bordes */
}

/* Contenedor de productos químicos */
.contenedor-regencia {
    display: flex;
    flex-direction: column;
    width: 100%; /* Cambiado de 100vw a 100% */
    background: rgba(255, 255, 255, 0);
    align-items: center;
    justify-content: center;
}

.box-regencia{
    height: 20%;
    width: 100%;
}

.Cartas-regencia {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Dos columnas */
    grid-template-rows: repeat(2, auto); /* Dos filas */
    gap: 40px; /* Espacio entre tarjetas */
    justify-items: center; /* Centrar elementos dentro de cada celda */
}

.svg-regencia .circle {
    fill: #7BD3BD;
}
.svg-regencia:hover .circle{
    fill: black;
}

.svg-regencia .circle2 {
    stroke: #7BD3BD;
}
.svg-regencia:hover .circle2{
    stroke: black;
}

.svg-regencia {
    height: 10vh;
    width: 6vw;
    background: rgb(0, 0, 0);
    border-radius: 20px;
    padding: 20px;
    transition: background 0.3s ease;
}

.svg-regencia:hover {
    height: 10vh;
    width: 6vw;
    background: #7BD3BD;
    border-radius: 20px;
    padding: 20px;
    transition: background 0.3s ease;
}

/* Estilos para pantallas pequeñas */
@media only screen and (max-width: 903px) {
    .body-regencia {
        overflow-x: unset; /* Asegúrate de que no oculta el overflow-x innecesariamente */
    }
    .img-regencia {
        position: relative; /* Permite posicionar elementos hijos */
        width: 100%; /* Ancho completo */
        height: 20vh; /* Altura deseada */
        overflow: hidden; /* Oculta el desbordamiento */
        margin-bottom: 5vh;
        background-color: black;
    }

    .contenedor-regencia {
        height: auto;
        width: 100%; /* Cambiado de 100vw a 100% */
        margin-top: 0vh; /* Margen superior */
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
    }

    .fondo-regencia {
        position: absolute; /* Cambiado a absolute para que la imagen ocupe el contenedor */
        top: 0; /* Ajustado para que esté en la parte superior */
        left: 0; /* Ajustado para que esté alineado a la izquierda */
        width: 100%; /* Ajusta el ancho de la imagen */
        height: 100%; /* Ajusta la altura de la imagen */
        object-fit: cover; /* Mantiene la proporción mientras cubre el área */
        z-index: 1; /* Asegura que la imagen esté detrás del contenido */
    }

    .box-regencia {
        width: calc(20% - 20px); /* Ajuste para tres elementos por fila */
        height: 20vh;
        margin-bottom: 8vh;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        flex-direction: column;
    }

    .svg-regencia {
        height: 100px; /* Altura fija para el SVG */
        width: 100px; /* Ancho fijo para el SVG */
        background: rgb(0, 0, 0); /* Fondo negro */
        border-radius: 20px; /* Esquinas redondeadas */
        padding: 20px; /* Espaciado interno */
    }

    .Cartas-regencia {
        display: flex;
        flex-direction: column;
        justify-items: center; /* Centrar elementos dentro de cada celda */
        align-items: center;
    }
}
