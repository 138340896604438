/* GraciasPage.css */
.gracias-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #2A2A2A;
  }
  
  .message-box {
    text-align: center;
    background: linear-gradient(145deg, #7BD3BD, #007352);
    border-radius: 12px;
    padding: 40px 60px;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1), -10px -10px 30px rgba(255, 255, 255, 0.3);
    max-width: 70%;
    width: 100%;
  }
  
  .thanks-message {
    font-size: 2.5rem;
    color: #000000;
    margin-bottom: 10px;
  }
  
  .sub-message {
    font-size: 1.2rem;
    color: #000000;
    margin-bottom: 20px;
  }
  
  .go-home-button {
    display: inline-block;
    background-color: #000000;
    color: #7BD3BD;
    font-size: 1.2rem;
    padding: 15px 30px;
    border-radius: 25px;
    text-decoration: none;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  
  .go-home-button:hover {
    background-color: #7BD3BD;
    color: #000;
    transform: scale(1.05);
  }
  
.go-home-button:active {
    transform: scale(1);
}
