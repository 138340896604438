body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Evita el desplazamiento horizontal */
    overflow-y: auto; /* Permitir desplazamiento vertical */
}

.body-nosotros{
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100vw;
    height: auto;
    
}

.padreNosotros{
    background-color: red;
    margin-top: 15vh;
    width: 100vw;
    height: auto;
}


.padreNosotros > div{
    width: 100vw;
    min-height: 85vh;
    height: auto;
}

.padreNosotros > div:nth-child(1){
    background-color: blue;
    display: flex;
}

.padreNosotros > div:nth-child(1) > div:nth-child(1){
    background-color: #131112;
    color: red;
    width: 65vw;
    padding: 10vh;
    box-sizing: border-box;
}
.padreNosotros > div:nth-child(1) > div:nth-child(1) h1{
    color: #7BD3BD;
    display: flex;
}
.padreNosotros > div:nth-child(1) > div:nth-child(1) p{
    color: #7BD3BD;
    display: flex;
    text-align: justify;
}
.padreNosotros > div:nth-child(1) > div:nth-child(1) > div{
    margin-top: 5vh;
}
.linkNosotros{
    text-align: justify;
    font-size: 15pt;
    border-radius: 20px;
    padding: 10px;
    margin: 2vh;
    color: transparent;
}
.linkNosotros:nth-child(1){
    background-color: #7BD3BD;
    color: #131112;
}
.linkNosotros:nth-child(2){
    color: #7BD3BD;
    border: #7BD3BD solid;
}


.padreNosotros > div:nth-child(1) > div:nth-child(2){
    background-color: #131112;
    height: 85vh;
    width: 35vw;
    overflow: hidden;
}

.padreNosotros > div:nth-child(1) > div:nth-child(2) > img{
    width: 100%;
    height: 100%; /* Mantener proporciones */
    border-radius: 20px; /* Bordes redondeados */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Sombra */
    object-fit: cover; 
    object-position: 40%;
    transform: translateX(20px);
}

.padreNosotros > div:nth-child(2){
    background-color: #131112;
}
.padreNosotros > div:nth-child(2) h1{
    color: #7BD3BD;
    margin: 0;
    box-sizing: border-box;
    padding: 10vh;
}
.padreNosotros > div:nth-child(2) > div{
    display: flex;
    justify-content: center;
    height: 60%;
}
.padreNosotros > div:nth-child(2) > div > div{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.padreNosotros > div:nth-child(2) > div > div > h2{
    display: flex;
    justify-content: center;
}
.padreNosotros > div:nth-child(2) > div > div > p{
    display: flex;
    justify-content: start;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.padreNosotros > div:nth-child(2) > div > div:nth-child(1){
    /*background-color: red;*/
    margin-left: 10vw;
}
.padreNosotros > div:nth-child(2) > div > div:nth-child(2){
    /*background-color: rgb(0, 255, 13);*/
}
.padreNosotros > div:nth-child(2) > div > div:nth-child(3){
    /*background-color: rgb(21, 0, 255);*/
    margin-right: 10vw;
}

.padreNosotros > div:nth-child(3){
    background-color: rgb(27, 27, 27);
    display: flex;
    flex-direction: row;
}
.padreNosotros > div:nth-child(3) > div:nth-child(1){
    height: 85vh;
    width: 35vw;
    overflow: hidden;
    background-color: #131112;
}
.padreNosotros > div:nth-child(3) > div:nth-child(1) > img{
    width: 100%;
    height: 100%; /* Mantener proporciones */
    border-radius: 20px; /* Bordes redondeados */
    object-fit: cover; 
    object-position: 20% 20%;
    transform: translateX(-20px);
}
.padreNosotros > div:nth-child(3) > div:nth-child(2){
    width: 65%;
    background-color: #131112;
    display: flex;
    flex-direction: column;
}
.padreNosotros > div:nth-child(3) > div:nth-child(2) > div:nth-child(1){
    height: 10%;
}
.padreNosotros > div:nth-child(3) > div:nth-child(2) > div:nth-child(1) > h2{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
    background-color: #131112;
    color: #7BD3BD;
    font-size: 25pt;
}
.padreNosotros > div:nth-child(3) > div:nth-child(2) > div:nth-child(2){
    width: 100%;
    height: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    gap: 0;
}
.padreNosotros > div:nth-child(3) > div:nth-child(2) > div:nth-child(2) > div{
    width: 35%;
    height: 50%;
    flex: auto;
}
.padreNosotros > div:nth-child(3) > div:nth-child(2) > div:nth-child(2) > div > h2{
    color: #7BD3BD;
    text-align: center;
    padding-right: 5px;
}
.padreNosotros > div:nth-child(3) > div:nth-child(2) > div:nth-child(2) > div > p{
    text-align: justify;
    padding: 20px;
    font-size: 14pt;
}

.padreNosotros > div:nth-child(4){
    background-color: #7BD3BD;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../jsx/img/WBEP/Nosotros3.webp');
    background-size: cover; /* La imagen cubre todo el contenedor */
    background-position: 20% 30%; /* Mueve la imagen de fondo */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    width: 100%; /* O el tamaño deseado */
    height: 100%; /* O el tamaño deseado */
}
.padreNosotros > div:nth-child(4) > div{
    background-color: #000000c3;
    border-radius: 15px;
    width: 90%;
    min-height: 90%;
    height: auto;
}

.padreNosotros > div:nth-child(4) > div > div:nth-child(1){
    color: white;
    display: flex;
    justify-content: center;
}

.padreNosotros > div:nth-child(4) > div > div:nth-child(2){
    width: 100%;
    height: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    justify-content: center;
    gap: 3vw;
}
.padreNosotros > div:nth-child(4) > div > div:nth-child(2) > div{
    width: 45%;
    height: 100%;
    border-radius: 10px;
    border: #7BD3BD solid 3px;
    display: flex;
    flex-direction: column;
    margin: 0 0 5vh 0;
}
.padreNosotros > div:nth-child(4) > div > div:nth-child(2) > div > h2{
    text-align: justify left;
    margin: 0 0 0 20pt;
}
.padreNosotros > div:nth-child(4) > div > div:nth-child(2) > div > p{
    text-align: justify;
    margin: 20pt;
    margin-top: 0;
}
.padreNosotros > div:nth-child(4) > div > div:nth-child(2) > div > div{
    display: flex;
    align-items: center;
    padding-left: 20px;
}

@media (max-width: 855px) {
    .padreNosotros > div{
        width: 100vw;
        min-height: 65vh;
        height: auto;
        box-sizing: border-box;
    }

    .linkNosotros{
        font-size: 10pt;
    }
    .padreNosotros > div:nth-child(1){
        height: 10vh;
    }
    .padreNosotros > div:nth-child(1) > div:nth-child(1){
        padding: 2vh;
        width: 100%;
    }
    .padreNosotros > div:nth-child(1) > div:nth-child(2){
        display: none;
    }
    .padreNosotros > div:nth-child(2) > h1{
        font-size: 20pt;
        padding: 2vw;
    }
    .padreNosotros > div:nth-child(3) > div:nth-child(1){
        display: none;
    }
    .padreNosotros > div:nth-child(3) > div:nth-child(2){
        width: 100%;
        padding-bottom: 5vh;
    }
    .padreNosotros > div:nth-child(4){
        padding-top: 5vh;
        background-position: 50% 30%; /* Mueve la imagen de fondo */
    }

    .padreNosotros > div:nth-child(4) > div > div:nth-child(2) > div > h2{
        font-size: 14pt;
    }
    .padreNosotros > div:nth-child(4) > div > div:nth-child(2) > div > p{
        font-size: 10pt;
    }
}