body, html {
    margin: 0;
    padding: 0;
    overflow-y: hidden; /* Evita el desplazamiento horizontal */
}
.body-home {
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.bodyImg {
    position: relative;
    margin-top: 10vh;
    width: 100%;     /* El contenedor tomará el 100% del ancho disponible */
    max-height: 90vh; /* Limita la altura máxima al 80% del viewport height */
    /* overflow: hidden; */ /* Oculta cualquier parte de la imagen que sobresalga */
    display: flex;    /* Asegura que el contenido esté alineado correctamente */
    align-items: center;
    justify-content: center;
}

.bodyImg img {
    max-width: 100%;   /* Asegura que la imagen no se desborde horizontalmente */
    max-height: 100%;  /* Asegura que la imagen no se desborde verticalmente */
    width: auto;       /* Mantiene la relación de aspecto */
    height: auto;      /* Mantiene la relación de aspecto */
    opacity: 50%;   
}
.titleInicio {
    display: flex;          /* Activa Flexbox */
    flex-direction: column; /* Alinea los elementos verticalmente */
    align-items: center;    /* Centra los elementos horizontalmente */
    justify-content: center;/* Centra los elementos verticalmente */
    text-align: center;     /* Alinea el texto de los elementos al centro */
    position: absolute;     /* Mantiene la posición absoluta sobre la imagen */
    left: 50%;              /* Centra horizontalmente */
    top: 50%;               /* Centra verticalmente */
    transform: translate(-50%, -50%); /* Ajusta la posición para que esté perfectamente centrada */
    z-index: 1;             /* Asegura que el contenido esté sobre la imagen */
    color: white;           /* Color del texto */
    width: 90vw;
}

.titleInicio h1, .titleInicio h2, .titleInicio p {
    margin: 10px 0; /* Añade margen entre los elementos */
    color: white;
}



.titleInicio p {
    top: 40%; /* Posición vertical para el párrafo */
}
.linkServicios {
    margin-top: 20px; /* Añade un margen superior para separarlo del texto anterior */
    font-size: 20pt;
    z-index: 2;
    background-color: black;
    color: white;
    padding: 15px;
    border-radius: 100px;
    transition: 0.5s;
    text-decoration: none; /* Quita el subrayado del enlace */
    display: inline-block; /* Para que el enlace respete el tamaño del contenido */
}


.linkServicios:hover{
    background-color: #7BD3BD;
    color: black;
}

.titleInicio h1 {
    top: 10%;                /* Posición vertical específica para h1 */
    font-size: 2.5em;       /* Tamaño del texto para h1 */
}

.titleInicio h2 {
    top: 30%;                /* Posición vertical específica para h2 */
    font-size: 1.5em;       /* Tamaño del texto para h2 */
}

.titleInicio p {
    top: 40%;                /* Posición vertical específica para p */
    font-size: 1.5em;       /* Tamaño del texto para p */
}

@media only screen and (max-width: 903px) {
    .body-home {
        width: 100%;
        height: 100vh;              /* Asegura que el contenedor ocupe toda la altura de la pantalla */
        background-color: rgb(0, 0, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;           /* Oculta cualquier contenido que sobresalga */
    }
    
    .titleInicio h1 {
        font-size: 1.5em;
    }
    
    .titleInicio h2 {
        font-size: 1em;
    }
    
    .titleInicio p {
        font-size: 1em;
    }

    .linkServicios {
        margin-top: 0px;
        font-size: 12pt;
        background-color: rgb(0, 0, 0);
        color: white;
        padding: 10px;
    }

    .bodyImg {
        position: absolute;         /* Posición absoluta para cubrir todo el viewport */
        top: 0;                     /* Alinea en la parte superior de la pantalla */
        left: 0;                    /* Alinea en la parte izquierda de la pantalla */
        width: 100vw;               /* Asegura que ocupe el 100% del ancho */
        height: 100vh;              /* Asegura que ocupe el 100% de la altura */
        overflow: hidden;           /* Oculta cualquier parte de la imagen que sobresalga */
    }

    .bodyImg img {
        width: 100vw;               /* Asegura que la imagen ocupe todo el ancho */
        height: 100vh;              /* Asegura que la imagen ocupe toda la altura */
        object-fit: cover;          /* Hace que la imagen cubra todo el espacio del contenedor sin distorsionarse */
        transform: none;            /* Elimina la escala en pantallas pequeñas */
        opacity: 50%;
    }
}
