.login-body {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    font-family: Arial, sans-serif;
    overflow-y: hidden;
    width: 100vw;
    background: linear-gradient(180deg, #2c2c2c 0%, #2c2c2c 50%, #ffffff 60%, #ffffff 100%);}

  
.login-container {
    position: relative;
    width: 100vw; /* Ancho del contenedor al 100% del ancho de la ventana gráfica */
    max-width: 800px;
    background-color: rgb(255, 255, 255);
   /* backdrop-filter: blur(100px);*/
    border-radius: 50px;
    box-shadow: 0px 20px 10px 0px #2c2c2c;
    padding: 30px;
    margin: 50px;
    color: black;
    overflow: hidden;
}
.bottonDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.login-container h1{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
  
.login-form h1 {
    color: #333333;
    margin-bottom: 30px;
}
   
.login-label {
    display: block;
    margin-bottom: 5px;
    color: #333333;
    font-size: 1.5em;
}
   
.login-input {
    width: 98%;
    padding: 10px;
    border: 2px solid #cccccc;
    background: white;
    border-radius: 3px;
    margin-bottom: 20px;
    color: black;
}
   
.login-button {
    width: 100%;
    padding: 10px;
    background-color: #2c2c2c;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s;
    font-size: 1.5em;
}
   
.login-button:hover {
    background-color: #0056b3;
}
.Botton-google-register{
    transition: 1s;
    margin-top: 10px;
}
.Botton-google-register:hover{
    transition: 1s;
    background-color: rgb(86, 86, 86);
}
@media only screen and (max-width: 880px) {
    .Botton-google-register{
        left: 50%;
        transform: translateX(-25%);
    }
}
.fade-out {
    animation: fadeOut 0.5s ease forwards;
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      visibility: hidden;
      height: 0;
      padding: 0;
    }
}
