.body-cotizar {
    background-color: none;
}

.parent-cotizar {
    width: 100vw;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.child-cotizar:first-child {
    color: rgb(255, 255, 255);
    width: 100vw;
    height: 100vh;
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative; /* Necesario para el pseudoelemento */
    z-index: 1; /* Asegura que el contenido esté encima del fondo */
    background-color: rgb(0, 0, 0);
}

/* Fondo con opacidad en ::before */
.child-cotizar:first-child::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./../jsx/img/Consulta.jpg');
    background-size: cover;
    background-position: center;
    opacity: 0.5; /* Controla la transparencia del fondo */
    z-index: -1; /* Pone el fondo detrás del contenido */
}

.child-cotizar:first-child h1 {
    margin: 0;
    padding: 0;
}

.child-cotizar:first-child .titulo {
    padding-top: 5vh;
    color: white;
}

.child-cotizar:first-child p {
    color: rgb(255, 255, 255);
    padding-inline: 20%;
    margin: 0;
    text-align: center;
}

.child-cotizar:nth-child(2) {
    background-color: rgb(170, 170, 170);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.child-cotizar:nth-child(2) p {
    text-align: center;
    margin: 10px;
}

.form-cotizar {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-cotizar form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100vw;
    max-width: 65vw;
    min-width: 50vw;
}

.form-cotizar input {
    width: 100%;
    height: 30pt;
    border-radius: 10px;
    background-color: white;
    color: black;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
}

.child-cotizar:nth-child(2) .mensaje {
    font-size: 10pt;
    grid-column: span 2;
    width: 100%;
    height: 15vh;
    resize: none;
    background-color: white;
    color: black;
    border: none;
    border-radius: 10px;
    font-family: Arial, Helvetica, sans-serif;
}

.child-cotizar:nth-child(2) input[type='submit'] {
    background-color: white;
    grid-column: span 2;
    margin-bottom: 10px;
}
