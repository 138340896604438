.register-body {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    font-family: Arial, sans-serif;
    overflow-y: hidden;
    width: 100vw;
    background: linear-gradient(180deg, #2c2c2c 0%, #2c2c2c 50%, #ffffff 60%, #ffffff 100%);
}

  
.register-container {
    position: relative;
    width: 100vw; /* Ancho del contenedor al 100% del ancho de la ventana gráfica */
    max-width: 800px;
    background-color: rgb(255, 255, 255);
   /* backdrop-filter: blur(100px);*/
    border-radius: 50px;
    box-shadow: 0px 20px 10px 0px #2c2c2c;
    padding: 30px;
    margin: 50px;
    color: black;
    overflow: hidden;
}

.register-container h1{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
  
   .register-form h1 {
    color: #333333;
    margin-bottom: 30px;
   }
   
   .register-label {
    display: block;
    margin-bottom: 5px;
    color: #333333;
    font-size: 1.5em;
   }
   
   .register-input {
    width: 98%;
    padding: 10px;
    border: 2px solid #cccccc;
    background: white;
    border-radius: 3px;
    margin-bottom: 20px;
    color: black;
   }
   
   .register-button {
    width: 100%;
    padding: 10px;
    background-color: #2c2c2c;
    color: #ffffff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 1.5em;
   }
   
   .register-button:hover {
    background-color: #0056b3;
   }
.login-imagenEstilizada {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100px;
    height: 100px;
    height: auto;
    filter: brightness(100%);
    fill: blue !important;
    color: blue;
}