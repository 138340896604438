.info-container {
  width: 100vw;
  background-color: #f0f0f0;
  padding: 15px;
  box-sizing: border-box;
  overflow: hidden; /* Asegura que el contenido no se vea hasta que se expanda */
  transition: max-height 0.5s ease; /* Suave crecimiento */
  max-height: 100px; /* Altura inicial cuando está colapsado */
}

.info-container.expanded {
  overflow: auto; /* Permite el desplazamiento */
  max-height: 1000px; /* Ajusta la altura máxima para el contenedor expandido */
}

.info-title {
  cursor: pointer;
  font-size: 24px;
  color: #333;
  text-align: center;
}

.info-details {
  margin-top: 15px;
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 5px;
  opacity: 1;
  color: black;
}

.info-subtitle {
  font-size: 20px; /* Tamaño de los subtítulos */
  margin-top: 10px; /* Margen superior para los subtítulos */
  margin-bottom: 5px; /* Margen inferior para los subtítulos */
  color: #555; /* Color de los subtítulos */
  font-weight: bold; /* Negrita para los subtítulos */
}

.info-details p {
  margin: 5px 0; /* Margen para los párrafos dentro de los detalles */
  line-height: 1.5; /* Espaciado entre líneas para mejor legibilidad */
}
