.contenedor-Boton {
  position: fixed; /* Posición fija respecto al viewport */
  bottom: 20px; /* Distancia desde la parte inferior */
  right: 20px; /* Distancia desde la derecha */
  z-index: 1; /* Ajusta según sea necesario */
}

.svg-gmail {
  height: 80%;
}
.svg-instagram {
  height: 80%;
}
.svg-tiktok {
  height: 70%;
}
.svg-facebook {
  height: 100%;
}
.svg-whatsapp {
  height: 80%;
}
.svg-chat {
  height: 100%;
}
.svg-linkedin {
  height: 80%; /* Ajuste para el tamaño del SVG de LinkedIn */
  width: 80%; /* Asegúrate de que tenga un ancho adecuado */
}

.boton-Boton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000000; /* Color de fondo del botón */
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 0vh;
  left: 0vw;
  z-index: 2;
}

.circulo-arriba, .circulo-arriba2, .circulo-arriba3, .circulo-arriba4, .circulo-arriba5, .circulo-arriba6  { /* Agregar .circulo-arriba3 */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #000000; /* Color de fondo del círculo */
  top: 0vh; /* Ajusta según tu diseño */
  left: 0vw; /* Ajusta según tu diseño */
  opacity: 0; /* Invisible por defecto */
  cursor: pointer;
  z-index: 1;
}

/* Whatsapp */
.circulo-arriba {
  animation: aparecer 0.9s alternate forwards;
  animation-delay: 0.5s;
}

/* Gmail */
.circulo-arriba2 {
  animation: aparecer2 0.9s alternate forwards;
  animation-delay: 0.4s;
}

/* LinkedIn */
.circulo-arriba3 { /* Nueva clase para el botón de LinkedIn */
  animation: aparecer3 0.9s alternate forwards; /* Nueva animación */
  animation-delay: 0.3s;
}

.circulo-arriba4{
  animation: aparecer4 0.9s alternate forwards; /* Nueva animación */
  animation-delay: 0.2s;
}

.circulo-arriba5{
  animation: aparecer5 0.9s alternate forwards; /* Nueva animación */
  animation-delay: 0.1s;
}

.circulo-arriba6{
  animation: aparecer6 0.9s alternate forwards; /* Nueva animación */
}

/* Keyframes para Instagram */
@keyframes aparecer6 {
  0% {
    opacity: 0;
    transform: translateY(0%) scale(0.1);
  }
  50% {
    opacity: 1;
    transform: translateY(-840%) scale(1); /* Ajusta la posición según lo que necesites */
  }
  100% {
    opacity: 1;
    transform: translateY(-820%) scale(1);
  }
}

/* Keyframes para Facebook */
@keyframes aparecer5 {
  0% {
    opacity: 0;
    transform: translateY(0%) scale(0.1);
  }
  50% {
    opacity: 1;
    transform: translateY(-700%) scale(1); /* Ajusta la posición según lo que necesites */
  }
  100% {
    opacity: 1;
    transform: translateY(-680%) scale(1);
  }
}

/* Keyframes para Instagram */
@keyframes aparecer4 {
  0% {
    opacity: 0;
    transform: translateY(0%) scale(0.1);
  }
  50% {
    opacity: 1;
    transform: translateY(-560%) scale(1); /* Ajusta la posición según lo que necesites */
  }
  100% {
    opacity: 1;
    transform: translateY(-540%) scale(1);
  }
}

/* Keyframes para LinkedIn */
@keyframes aparecer3 {
  0% {
    opacity: 0;
    transform: translateY(0%) scale(0.1);
  }
  50% {
    opacity: 1;
    transform: translateY(-420%) scale(1); /* Ajusta la posición según lo que necesites */
  }
  100% {
    opacity: 1;
    transform: translateY(-400%) scale(1);
  }
}
/* Keyframes para Whatsapp */
@keyframes aparecer {
  0% {
    opacity: 0;
    transform: translateY(0%) scale(0.1);
  }
  50% {
    opacity: 1;
    transform: translateY(-140%) scale(1);
  }
  100% {
    opacity: 1;
    transform: translateY(-120%) scale(1);
  }
}
/* Keyframes para Gmail */
@keyframes aparecer2 {
  0% {
    opacity: 0;
    transform: translateY(0%) scale(0.1);
  }
  50% {
    opacity: 1;
    transform: translateY(-280%) scale(1);
  }
  100% {
    opacity: 1;
    transform: translateY(-260%) scale(1);
  }
}
/* Keyframes para Whatsapp */
@keyframes desaparecer {
  0% {
    opacity: 1;
    transform: translateY(-120%) scale(1);
    z-index: 1;
  }
  50% {
    opacity: 1;
    transform: translateY(-140%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(0%) scale(1);
  }
}
/* Keyframes para Instagram*/
@keyframes desaparecer1 {
  0% {
    opacity: 1;
    transform: translateY(-260%) scale(1);
    z-index: 1;
  }
  50% {
    opacity: 1;
    transform: translateY(-280%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(0%) scale(1);
  }
}
/* Keyframes para Gmail */
@keyframes desaparecer2 {
  0% {
    opacity: 1;
    transform: translateY(-260%) scale(1);
    z-index: 1;
  }
  50% {
    opacity: 1;
    transform: translateY(-280%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(0%) scale(1);
  }
}
@keyframes desaparecer3 { /* Nueva animación de desaparición para LinkedIn */
  0% {
    opacity: 1;
    transform: translateY(-400%) scale(1);
    z-index: 1;
  }
  50% {
    opacity: 1;
    transform: translateY(-420%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(0%) scale(1);
  }
}
@keyframes desaparecer4 { /* Nueva animación de desaparición para Instagram */
  0% {
    opacity: 1;
    transform: translateY(-540%) scale(1);
    z-index: 1;
  }
  50% {
    opacity: 1;
    transform: translateY(-560%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(0%) scale(1);
  }
}
@keyframes desaparecer5 { /* Nueva animación de desaparición para LinkedIn */
  0% {
    opacity: 1;
    transform: translateY(-680%) scale(1);
    z-index: 1;
  }
  50% {
    opacity: 1;
    transform: translateY(-700%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(0%) scale(1);
  }
}
@keyframes desaparecer6 { /* Nueva animación de desaparición para LinkedIn */
  0% {
    opacity: 1;
    transform: translateY(-820%) scale(1);
    z-index: 1;
  }
  50% {
    opacity: 1;
    transform: translateY(-840%) scale(1);
  }
  100% {
    opacity: 0;
    transform: translateY(0%) scale(1);
  }
}
.circulo-arriba.desaparecer {
  animation: desaparecer 0.6s alternate forwards;
}
.circulo-arriba2.desaparecer2 {
  animation: desaparecer2 0.6s alternate forwards;
}
.circulo-arriba3.desaparecer3 { /* Nueva clase para el botón de LinkedIn */
  animation: desaparecer3 0.6s alternate forwards; /* Nueva animación */
}
.circulo-arriba4.desaparecer4 { /* Nueva clase para el botón de LinkedIn */
  animation: desaparecer4 0.6s alternate forwards; /* Nueva animación */
}
.circulo-arriba5.desaparecer5 { /* Nueva clase para el botón de LinkedIn */
  animation: desaparecer5 0.6s alternate forwards; /* Nueva animación */
}
.circulo-arriba6.desaparecer6 { /* Nueva clase para el botón de LinkedIn */
  animation: desaparecer6 0.6s alternate forwards; /* Nueva animación */
}

