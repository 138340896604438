.container {
  box-sizing: border-box;
  margin-top: 10vh;
  height: 90vh;
  padding: 0;
  max-width: 100%;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2c2c2c;
  color: black;
  overflow-x: hidden;
}
.buttonCorreo{
  background-color: rgb(196, 15, 15);
}
.contenedor-form:nth-child(1){
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: start;
}
.column {
  background: #2c2c2c;
  height: 100%;
  
  box-sizing: border-box; /* Incluye el padding en el ancho de la columna */

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
}

.column:nth-child(1){
  position: absolute;
  width: 40vw;
  height: 90%;
  left: 0;
}
.column:nth-child(2){
  visibility: hidden;
  width: 40vw;
}
.column:nth-child(3){
  border-right: none;
  width: 20vw;
  color: white;
  font-size: 1.5em;
  margin: 0;
  position: absolute;
  right: 0;
  height: 90%;
}
.column1{
  background: white;
  border-radius: 10px;
  padding: 20px;
  color: black;
  box-shadow: 0px 0px 20px 1px black;
  text-align: center;
  font-size: 16pt;
  width: auto;
  min-width: 10vw;
}
.column:nth-child(3) p{
  margin: 0;
}

.FormLeft{
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 0px 20px 1px black;
  border-radius: 20px;
  padding: 15px;
  margin-inline: 20px;
}

.pregunta-form{
  font-size: 1.5em;
}

.etiqueta-Text{
  color: rgb(0, 0, 0);
  font-size: 1.5em;
}

.Checkbox{
  transform: scale(1.5);
}
.contenedor-form{
  display: flex;
  align-items: center;
  justify-content: start;
}
.contenedor-form select{
  background: rgb(255, 255, 255);
  color: black;
  border: solid black 1px;
  font-size: 1.5em;
  border-radius: 50px;
}
.Text-transparente{
  color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
  border: solid black 1px;
  font-size: 1.5em;
  border-radius: 50px;
}

.FormRight{
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 0px 20px 1px black;
  border-radius: 20px;
  padding: 15px;
  margin-inline: 20px;
}

.contenedor-form2{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contenedor-form2 input{
  background: white;
  border: solid black 1px;
  border-radius: 50px;
  font-size: 1.5em;
  color: black;
}

.contenedor-form2:nth-child(4) input{
  background: white;
  border: solid black 1px;
  font-size: 1em;
  color: rgb(0, 0, 0);
}

.popup-uploadFile{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  padding: 100px;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 0px 20px 1px black;
  z-index: 999;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup-uploadFile p {
  margin-bottom: 10px;
}

.popup-uploadFile a {
  display: block;
  margin-top: 10px;
  color: blue;
}


@media only screen and (max-width: 1200px) {
  .container {
    flex-direction: column;
    background-color: #2c2c2c;
    height: 100vh;
    width: 100vw;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
  
  .column{
    border: none;
    margin: 20px;
    margin-inline: 0;
  }
  .column:nth-child(1) {
    width: 100vw;
    height: 100vh;
    margin-top: 15vh;
    position: relative;
  }
  .column:nth-child(2){
    width: 100vw;
    height: 0px;
    margin: 0;
    padding: 0;
  }
  .column:nth-child(3){
    width: 100vw;
    margin: 0;
    padding: 0;
    position: relative;
  }

  .FormLeft{
    margin-top: 100px;
  }

  .pregunta-form{
    font-size: 1em;
  }
  
  .etiqueta-Text{
    color: rgb(0, 0, 0);
    font-size: 1em;
  }

  .popup-uploadFile{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -25%);
    background-color: rgb(255, 255, 255);
    padding: 70px;
    width: 35vw;
    height: 20vh;
    border: none;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 1px rgb(0, 0, 0);
    z-index: 999;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .popup-uploadFile p {
    margin-bottom: 10px;
  }
  
  .popup-uploadFile a {
    display: block;
    margin-top: 10px;
    color: rgb(0, 38, 255);
  }

  .contenedor-form select{
    font-size: 1em;
  }
  .Checkbox{
    transform: scale(1.4);
    position: flex;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(48, 48, 48, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loader {
  border: 8px solid #ffffff;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.disclaimer-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.disclaimer-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button_upload {
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button_upload:hover {
  background-color: #45a049;
}
