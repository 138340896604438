.parent-consulta{
    width: 100vw;
    height: 100vh;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.child-consulta:first-child{
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative; /* Necesario para el pseudoelemento */
    z-index: 1; /* Asegura que el contenido esté encima del fondo */
    background-color: rgb(0, 0, 0);
}
.child-consulta:first-child::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./../jsx/img/Consulta.jpg');
    background-size: cover;
    background-position: center;
    opacity: 0.5; /* Controla la transparencia del fondo */
    z-index: -1; /* Pone el fondo detrás del contenido */
    background-position-x: 0;
    background-position-y: 0;
}
.child-consulta:first-child{
    margin: 0;
    padding: 0;
    background-color: rgb(170, 170, 170);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(0, 0, 0);
}
.child-consulta p, h1{
    text-align: center;
    color: white;
}
.tituloConsulta{
    color: white;
}
.form-consulta form{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
    margin: 0;
    max-width: 50vw;
    margin-inline: 25vw;
}
.form-consulta input {
    width: 100%;
    height: 30pt;
    border-radius: 10px;
    background-color: white;
    color: black;
    border: none;
}
.mensaje {
    font-size: 10pt;
    grid-column: span 2;
    height: 20vh;
    width: 100%;
    resize: none;
    background-color: white;
    color: black;
    border: none;
    border-radius: 10px;
}
.child-consulta:first-child input[type='submit']{
    background-color: white;
    grid-column: span 2;
}
/* Agrega esto a tu archivo consulta.css */

.warning-message {
    position: fixed;
    bottom: -100px; /* Inicialmente fuera de la pantalla */
    left: 50%;
    transform: translateX(-50%);
    background-color: #ff0000;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    z-index: 1000;
    transition: bottom 0.5s ease; /* Transición suave para el movimiento */
}
  
.warning-message.show {
    bottom: 20px; /* Posición final cuando se muestra */
}

.success-message {
    position: fixed;
    bottom: -100px; /* Inicialmente fuera de la pantalla */
    left: 50%;
    transform: translateX(-50%);
    background-color: #15ff00;
    color: #000; /* Cambié el color del texto a negro para mayor visibilidad */
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    z-index: 1000;
    transition: bottom 0.5s ease; /* Transición suave para el movimiento */
}
    
  .success-message.show {
    bottom: 20px; /* Mostrar cuando la clase 'show' está activa */
}
  