.body-services {
    display: flex;
    align-items: center;
    height: 100vh;
    background-color: #ffffff;
    margin-top: 0vh;
    width: 100vw;
    overflow-y: auto; /* Permite el desplazamiento vertical */
}


.contenedor-servicio {
    margin-top: 15vh;
    display: grid;
    width: 100vw;
    background: rgba(255, 255, 255, 0);
    grid-template-columns: repeat(3, 1fr); /* Cambiado a 3 columnas */
    gap: 40px; /* Espacio entre tarjetas */
    justify-items: center; /* Centrar elementos dentro de cada celda */
}

.box-servicio {
    height: 100%; /* Altura fija */
    width: 10vw; /* Ancho fijo */
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    display: flex; /* Cambiado a flex para centrar el contenido */
    flex-direction: column;
}

.box-servicio p {
    margin: 0;
    color: black;
    text-align: center; /* Centrar texto */
}

.svg-Servicio {
    height: 10vh;
    width: 6vw;
    background: rgb(0, 0, 0);
    border-radius: 20px;
    padding: 20px;
    transition: background 0.3s ease;
}

.svg-Servicio:hover {
    background: #7BD3BD;
}

/* Estilos SVG */
.svg-Servicio .cls-1,
.svg-Servicio .cls-2,
.svg-Servicio .t,
.svg-Servicio .e,
.svg-Servicio .d,
.svg-Servicio .f,
.svg-Servicio .g {
    stroke: #7BD3BD; /* Color del trazo */
    fill: none;
}

.svg-Servicio {
    fill: #7BD3BD;
}

.svg-Servicio:hover .cls-1,
.svg-Servicio:hover .cls-2,
.svg-Servicio:hover .cls-3,
.svg-Servicio:hover .cls-4,
.svg-Servicio:hover .cls-5,
.svg-Servicio:hover .t,
.svg-Servicio:hover .e,
.svg-Servicio:hover .d,
.svg-Servicio:hover .f,
.svg-Servicio:hover .g {
    stroke: rgb(0, 0, 0); /* Cambia el color del trazo al hacer hover */
    fill: none; /* Cambia el relleno al hacer hover */
}

.box-servicio:hover .svg-Servicio {
    fill: black;
}

.link-servicio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; /* Centrar texto */
    text-decoration: none; /* Sin subrayado */
    color: black; /* Color del texto */
}

.box-parrafo {
    color: black;
    padding-top: 20px;
    padding-inline: 7%;
    text-align: justify;
}

@media only screen and (max-width: 903px) {
    .body-services {
        overflow-x: hidden;
    }

    .contenedor-servicio {
        height: 100vh;
        width: 100vw;
        margin-top: 40vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
    }
    .svg-Servicio {
        height: 100px;
        width: 100px;
        border-radius: 20px;
        padding: 20px;
    }

    .box-parrafo {
        display: none;
    }
}
