
.body-tabla{
    background-color: #2c2c2c;
}
.filtro-container{
    margin-top: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filtro-container select{
    padding: 10px;
    border-radius: 50px;
    font-size: 1em;
    background-color: #bfeabf;
    color: black;
}

.table-Tabla {
    margin-top: 2vh;
    margin-inline: 4vw;
    width: 90vw;
    border-collapse: collapse;
}

.table-Tabla th {
    background-color: #bfeabf;
    color: black;
    border: none;
    margin: 0;
    padding: 10px;
    text-align: center; /* Alinea el texto horizontalmente en el centro */
}

.table-Tabla th:nth-child(1) {
    border-radius: 50px 0 0 0;
}

.table-Tabla th:nth-child(9) {
    border-radius: 40px 40px 0 0 ;
    background-color: rgb(0, 0, 0);
    color: white;
}
.redondear-Tabla {
    border-radius: 0 50px 0 0;
}

.table-Tabla td {
    border: none;
    padding-block: 15px;
    margin: 0;
    color: rgb(255, 255, 255);
    text-align: center; /* Alinea el texto horizontalmente en el centro */
    vertical-align: middle; /* Alinea el texto verticalmente en el centro */
    font-size: 1em;
}
.table-Tabla td:nth-child(1){
    text-align: left;
}

.table-Tabla td:nth-child(1),
.table-Tabla td:nth-child(2),
.table-Tabla td:nth-child(4),
.table-Tabla td:nth-child(5),
.table-Tabla td:nth-child(6),
.table-Tabla td:nth-child(7),
.table-Tabla td:nth-child(8) {
    width: 15vw;
}

.table-Tabla tbody tr:nth-child(even) {
    background-color: #00000047; /* Color para filas pares */
}
.table-Tabla tbody tr:nth-child(odd) {
    background-color: #ffffff2f; /* Color para filas impares */
}

@media only screen and (max-width: 1200px) {

    .Hola{
        width: 100vw;
    }
   
    .table-Tabla {
        margin-top: 1vh;
        margin-inline: 4vw;
        width: 89vw;
        border-collapse: collapse;
    }

    .table-Tabla td {
        font-size: 1em;
        border-inline: white solid 1px;
        border-bottom: white solid 1px;
    }

    .table-container {
        overflow-x: scroll;
        max-width: 100%;
    }
    
    .table-Tabla {
        width: 110%; /* Ajusta el ancho de la tabla para que la barra se extienda más allá del contenedor visible */
        border-collapse: collapse;
        margin-bottom: 20px; /* Ajusta el margen inferior para separar la tabla del final del contenedor visible */
    }
}

.table-Tabla tr button{
    margin: 0;
    padding: 6px;
}

.delete-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(0, 0, 0);
    padding: 20px;
    border: 5px solid white;
    z-index: 1000;
    text-align: center;
}
.delete-modal p{
    font-size: 1.3em;
}  
.delete-modal button:nth-child(2){
    background-color: red;
}  