/* Estilos generales para el body */
body, html {
    margin: 0;
    padding: 0;
}
.texto-quimico{
    color: black;
    text-align: justify;
    font-size: 18pt;
    width: 80%;
}
.texto-notas{
    font-size: 16pt;
    margin: 0;
    margin-top: 10px;
}

/* Contenedor principal de productos químicos */
.body-productosquimicos {
    margin-top: 15vh;
    display: flex;
    flex-direction: column; /* Cambiado para permitir desplazamiento vertical */
    align-items: center;
    height: 100%;
    min-height: 100vh;
    width: 100vw; /* Cambiado de 100vw a 100% */
    background-color: #ffffff;
    overflow-y: auto; /* Permite el desplazamiento vertical si es necesario */
}



.img-productosquimicos {
    position: relative; /* Permite posicionar elementos hijos */
    width: 100%; /* Ancho completo */
    height: 25vh; /* Altura deseada */
    overflow: hidden; /* Oculta el desbordamiento */
    margin-bottom: 5vh;
    background-color: black;
}

.fondo {
    position: absolute; /* Cambiado a absolute para que la imagen ocupe el contenedor */
    top: -190%; /* Ajusta este valor para mover la imagen hacia arriba o abajo */
    left: 0; /* Alineado a la izquierda */
    width: 100%; /* Ajusta el ancho de la imagen */
    height: 100vh; /* Cambiado a auto para mantener la proporción */
    object-fit: cover; /* Mantiene la proporción mientras cubre el área */
    z-index: 1; /* Asegura que la imagen esté detrás del contenido */
    opacity: 0.5;
}

.img-productosquimicos h1 {
    position: relative; /* Permite que el texto esté por encima de la imagen */
    z-index: 2; /* Asegura que el texto esté en el frente */
    color: rgb(255, 255, 255); /* Ajusta el color según el contraste que desees */
    text-align: center; /* Centra el texto */
    padding: 20px; /* Espaciado interno para que el texto no esté pegado a los bordes */
}

/* Contenedor de productos químicos */
.contenedor-productosquimicos {
    display: flex;
    flex-direction: column;
    width: 100%; /* Cambiado de 100vw a 100% */
    background: rgba(255, 255, 255, 0);
    align-items: center;
    justify-content: center;
}

.svg-Servicio:hover .circle{
    fill: black;
}

.imgClass-RBM img{
    top: -130%;
}

.titleClass-RETMP{
    font-size: 36pt;
}

.textClass-Quimica{
    margin: 10px;
}

.imgClass-Alimentos img{
    top: -10%;
}

.imgClass-Cosmeticos img{
    top: -10%;
}

.imgClass-BPM img{
    top: -200%;
}

/* Estilos para pantallas pequeñas */
@media only screen and (max-width: 903px) {
    .body-services {
        overflow-x: unset; /* Asegúrate de que no oculta el overflow-x innecesariamente */
    }
    .img-productosquimicos {
        position: relative; /* Permite posicionar elementos hijos */
        width: 100%; /* Ancho completo */
        height: 20vh; /* Altura deseada */
        overflow: hidden; /* Oculta el desbordamiento */
        margin-bottom: 5vh;
        background-color: black;
    }

    .img-productosquimicos h1{
        font-size: 25pt;
    }

    .contenedor-productosquimicos {
        height: auto;
        width: 100%; /* Cambiado de 100vw a 100% */
        margin-top: 0vh; /* Margen superior */
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
    }

    .fondo {
        position: absolute; /* Cambiado a absolute para que la imagen ocupe el contenedor */
        top: 0; /* Ajustado para que esté en la parte superior */
        left: 0; /* Ajustado para que esté alineado a la izquierda */
        width: 100%; /* Ajusta el ancho de la imagen */
        height: 100%; /* Ajusta la altura de la imagen */
        object-fit: cover; /* Mantiene la proporción mientras cubre el área */
        z-index: 1; /* Asegura que la imagen esté detrás del contenido */
    }

    .box-productosquimicos {
        width: calc(20% - 20px); /* Ajuste para tres elementos por fila */
        height: 20vh;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        flex-direction: column;
    }

    .svg-Servicio {
        height: 100px; /* Altura fija para el SVG */
        width: 100px; /* Ancho fijo para el SVG */
        background: rgb(0, 0, 0); /* Fondo negro */
        border-radius: 20px; /* Esquinas redondeadas */
        padding: 20px; /* Espaciado interno */
    }

    .texto-quimico{
        color: rgb(0, 0, 0);
        text-align: justify;
        margin-inline: 50px;
    }

    .imgClass-Alimentos img{
        top: 0%;
    }
    
    .imgClass-Cosmeticos img{
        top: 0%;
    }

    .imgClass-RBM img{
        top: 0%;
    }

    .imgClass-BPM img{
        top: 0%;
    }
}
