.reset-password-container{
    background: linear-gradient(180deg, #2c2c2c 0%, #2c2c2c 50%, #ffffff 60%, #ffffff 100%);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
h1{
    margin: 0;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    color: black;
}
#message{
    color: red;
}
.reset-password-container form{
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-inline: auto;
    width: 100vw;
    max-width: 50vw;
    background-color: rgb(255, 255, 255);
    padding: 50px;
    border-radius: 25px;
    box-shadow: 0px 20px 10px 0px #2c2c2c;
}
.reset-password-container form input{
    background-color: rgb(255, 255, 255);
    border-radius: 50px;
    border: solid black 2px;
    color: rgb(0, 0, 0);
    padding: 10px;
    margin: 0px 0px 10px 0px;
}
.reset-password-container form button{
    background-color: rgb(0, 0, 0);
    border-radius: 50px;
}
.toggle-password-visibility.visible {
    color: red; /* Color cuando la contraseña es visible */
}
.toggle-password-visibility.hidden {
    color: blue; /* Color cuando la contraseña es oculta */
}
.password-input-container {
    position: relative;
    display: flex;
    align-items: center;
}
.password-input-container input {
    flex: 1;
}
.toggle-password-visibility {
    position: absolute;
    top: 12px;
    right: 10px;
    cursor: pointer;
    color: black; /* Color negro para el ícono */
}