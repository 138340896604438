.body-Drive {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: #2c2c2c;
  width: 100%;
}

.body2-drive{
  background-color: #2c2c2c;
}

.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Alinear elementos en columna */
}

.flex-container {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
  }

  .body-Drive {
    margin-top: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .folder {
    flex: 0 0 calc(33.33% - 10px); /* Para dividir en 3 columnas, ajusta según tus necesidades */
    box-sizing: border-box;
    width: 80vw;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    grid-template-rows: auto; /* Añade esta línea para tener solo una fila */
  }
  
  .folder {
    padding: 10px;
    border: none;
    background: yellowgreen;
    height: 12vh;
    cursor: pointer;
    border-radius: 20px ;
  }
  .folder-icon {
    margin-right: 10px; /* Ajusta el espacio entre el ícono y el texto según sea necesario */
  }
  .folder-exit{
    border: none;
    background: red;
    height: 8vh;
    cursor: pointer;
    

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
  }

  .document {
    cursor: pointer;
    padding: 10px;
    margin-bottom: 5px;
    border: none;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .text-file {
    background-color: #3498db; /* Azul */
  }
  
  .pdf-file {
    background-color: #e74c3c; /* Rojo */
  }
  
  .word-file {
    background-color: #2ecc71; /* Verde */
  }
  
  .image-file {
    background-color: #f39c12; /* Amarillo */
  }
  
  /* Puedes agregar más estilos para otros tipos de archivo */
  
  .default-file {
    background-color: #95a5a6; /* Gris */
  }
  

  .documents-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  /* Otros estilos según sea necesario */
  

  