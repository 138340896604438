.error-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Asegura que el cuerpo ocupe el 100% de la altura de la ventana */
    margin: 0; /* Elimina el margen predeterminado del cuerpo */
    background: rgb(0, 18, 61);
    overflow-y: hidden;
}   
.circle {
    position: absolute;
    width: 100px;
    height: 100px;
    background: radial-gradient(at top right, 
    rgb(255, 255, 255) 20%, 
    rgb(0, 208, 255)30%, 
    rgb(0, 208, 255)50%, 
    rgb(0, 0, 0)85%); /* Degradado radial de blanco a color original */
    border-radius: 50%;
    animation: rotateCircle 4s infinite linear; /* Animación de rotación del círculo */
    /*box-shadow: -10px 0 0 black;*/
  }
  
  .oval {
    position: absolute;
    width: 200px;
    height: 500px;
    border: solid 10px rgb(0, 208, 255);
    border-radius: 50%;
    animation: rotateOval 9s infinite linear; /* Animación de rotación del óvalo */
    box-shadow: -10px 0 0 black;

    
  }

  .oval:nth-child(2) {
    animation-delay: -3s; /* Retrasa la animación del segundo óvalo en 1 segundo */
  }
  
  .oval:nth-child(3) {
    animation-delay: -6s; /* Retrasa la animación del tercer óvalo en 2 segundos */
  }

  
  
  @keyframes rotateOval {
    0% {
      transform: rotate(0deg) rotateY(0deg) rotateZ(0deg) rotateX(0deg);
    }
    100% {
      transform: rotate(360deg) rotateY(360deg) rotateZ(360deg) rotateX(360deg);
    }
  }

.ERROR{
  position: absolute;
  align-items: center;
  top: -20px;
  font-size: 3em;
  margin: 20px;
}

.ERROR:hover{
  color: red;
  margin: 15px;
  transition: 0s;
}