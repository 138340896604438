/* botonNuevo.css */
.boton-nuevo {
    background-color: #000000;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16pt;
    border-radius: 50px;
    cursor: pointer;
    margin: 10px 0 30px 0;
  }
  
  .boton-nuevo:hover {
    background-color: #363636;
  }

.buttonCorreo{
  background-color: black;
  margin: 0;
  margin-top: 20pt;
}